import {registerBlockType} from '@wordpress/blocks';
import {__} from '@wordpress/i18n';
import Edit from "./edit";
import Save from "./save";

registerBlockType("plm/slide",{
    title: __("Slider"),
    description: __('Single slider content'),
    icon: "tide",
    parent:['plm/slider'],
    edit: Edit,
    save: Save
})