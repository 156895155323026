import { __ } from '@wordpress/i18n';
import { InnerBlocks } from '@wordpress/block-editor';

export default function Save(props){
   
    return(
        <div className='item-slider-content'>
            <InnerBlocks.Content />
        </div>

    );

    
}
