import { __ } from '@wordpress/i18n';
import { InnerBlocks } from '@wordpress/block-editor';
import "./index.css";

export default function save(props){
    const { attributes} = props;
    const { blockId, slidesToShow, slidesToScroll, responsive, autoplay, autoplaySpeed, arrows, dots, infinite} = attributes;

    const sliderVariables = `{"slidesToShow": ${slidesToShow}, "slidesToScroll": ${slidesToScroll}, "autoplay":${autoplay}, "autoplaySpeed": ${autoplaySpeed}, "arrows": ${arrows}, "dots": ${dots},"infinite":${infinite},"responsive":[{"breakpoint":780, "settings":{"slidesToShow":${responsive}}}]}`
   
    return(
        <div className='slidercontent-block' data-slick= {sliderVariables} >
            <InnerBlocks.Content />
        </div>

    );

    
}

